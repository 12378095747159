.ant-menu-item::after {
  border-right: none !important;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: none !important;
}

.ant-menu-item-selected {
  color: #de923b !important;
  border-radius: 0px 12px 70px 0px !important;
}
