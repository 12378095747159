.category__heading, .category__content .counting__wrapper span, .category__content .category__text h6,
.category__content .category__text span, .category__content .category__button{
    font-family: "Poppins" !important;
    font-style: normal;
    font-weight: 400;
}
.category__heading{
    font-size: 19px;
    color: #000;           
}

.category__content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
}

.category__content .category__inner{
    display: flex;
    justify-content: start;
    align-items: center;
}

.category__content .counting__wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 52px;
    height: 52px;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    margin-right: 10px;
}

.category__content .counting__wrapper__1{
    background: rgba(91, 147, 255, 0.1);
}
.category__content .counting__wrapper__2{
    background: rgba(255, 162, 0, 0.2);
}
.category__content .counting__wrapper__3{
    background: rgba(255, 9, 2, 0.1);
}
.category__content .counting__wrapper__4{
    background: rgba(0, 207, 232, 0.1);
}

.category__content .counting__wrapper__1 span{
    color: #5B93FF;
}
.category__content .counting__wrapper__2 span{
    color: #FFA200;
}
.category__content .counting__wrapper__3 span{
    color: #FF0902;
}
.category__content .counting__wrapper__4 span{
    color: #00CFE8;
}

.category__content .counting__wrapper span{
    font-weight: 700 !important;
    font-size: 19px;
    line-height: 28px;
}

.category__content .category__text h5{
    font-size: 14px;
    color: #969BA0;
}

.category__content .category__text span{
    font-size: 16px;
    color: #000000;
}

.category__content .category__button{
    font-size: 14px;
    background: #fff;
    border: 0;
    text-decoration: underline;
    color: #FFA200;
}

.create__product{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

@media screen and (max-width: 660px) {
    .create__product{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .create__product button{
        margin-left: 10px !important;
    }
}

@media screen and (min-width: 1200px) {
    .border-right-lg{
        border-right: 1px solid #e4e4e4;
    }
}


.product_header .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 30px !important;
}

