inqueryOrder__detail > * {
  font-family: "Poppins";
  font-style: normal;
}

.inqueryOrder__detail .inqueryOrder__detail__info {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.inqueryOrder__detail .inqueryOrder__detail__info h4 {
  width: 40%;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  padding-right: 10px;
}
.inqueryOrder__detail .inqueryOrder__detail__info span {
  width: 60%;
  font-weight: 400;
  font-size: 13px;
  color: rgba(102, 102, 102, 0.8);
}

.inqueryOrder__detail__form input:focus,
.inqueryOrder__detail__form input:focus-visible {
  outline: 0 !important;
  border: 0 !important;
  box-shadow: none !important;
}

.ant-drawer-mask{
  background: rgba(0, 0, 0, 0.12) !important;
}

.inqueryOrder__detail__form .ant-select-custom{
  background: #e9e9e9 !important;
  border-radius: 8px !important;
  height: 45px !important;
  line-height: 45px !important;
  font-size: 14px !important;
  border: 1px solid #e9e9e9 !important;
}

.inqueryOrder__detail__form .ant-select-selection-placeholder{
  height: 45px !important;
  line-height: 45px !important;
}

.inqueryOrder__detail__form .ant-select-selector{
  background: #e9e9e9 !important;
  border: 1px solid #e9e9e9 !important;
  height: 45px !important;
  line-height: 45px !important;
  box-shadow: none !important;
}

.inqueryOrder__detail__form .ant-select-selection-item{
  line-height: 45px !important;
}
