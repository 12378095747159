.other_item-mur {
    border-radius: 10px !important;
    background: #FFFFFF;
    border: 1px solid #D9D9D9 !important;
    border-bottom: 3px solid #1DBF73 !important;
    cursor: pointer;
    border-radius: 8px 8px 0px 0px !important;
    background: #FFFFFF !important;
    height: 225px !important;
    width: 100% !important;
}

.item-overall {
    width: 66px;
    height: 22px;
    padding: 5px;
    background: rgba(29, 191, 115, 0.1);
    border-radius: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    /* identical to box height */
    text-align: center;
    color: #1DBF73;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px auto;
}

.item_inner_dev .item-top-seller {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px !important;
    text-align: center;
    color: black;
    font-weight: bold;
}

.item_inner_dev .item-counting {
    font-size: 28px !important;
}

.item-top-seller {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #BBBBBB;
}

.item-counting {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 42px;
    line-height: 10px;
    color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px auto;
}

.item-button {
    box-sizing: border-box;
    width: 128px;
    height: 38px;
    border: 1px solid #D9D9D9;
    border-radius: 43px;
    /* VEIW MORE */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    background-color: white;
    font-size: 13px;
    text-align: center;
    color: #1DBF73;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px auto;
}

@media screen and (max-width: 340px) {
    .item-button{
        width: 108px;
    }
}