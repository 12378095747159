.item_mur {
    width: 100%;
    height: 220px;
    border-radius: 10px !important;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-bottom: 3px solid #1DBF73;
    border-radius: 8px 8px 0px 0px;
    cursor: pointer;
    text-align: center;
    padding: 10px;
}

.other_item_mur {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 220px;
    border-radius: 10px !important;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    /* border: 1px solid #D9D9D9;
    border-bottom: 3px solid #1DBF73; */
    border: 1px solid green !important;
    border-radius: 8px 8px 0px 0px;
}

.item_inner_dev {
    width: 100%;
    height: 150px;
}

.item_overall {
    padding: 5px;
    background: rgba(29, 191, 115, 0.1);
    border-radius: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    width: 80px;
    margin: 0 auto;
    text-align: center;
    color: #1DBF73;
    margin-bottom: 10px;
}

.item_top_seller {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 17px !important;
    text-align: center;
    color: #BBBBBB !important;
}

@media screen and (max-width: 660px) {
    .item_top_seller{
        font-size: 14px;
        margin-top: 5px;
    }
}

.item_counting {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px !important;
    color: #000000;
    margin-bottom: 10px;
}

.item_button {
    box-sizing: border-box;
    width: 100px !important;
    height: 38px;
    border: 1px solid #D9D9D9;
    border-radius: 43px;
    /* VEIW MORE */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    background-color: white;
    font-size: 13px;
    text-align: center;
    color: #1DBF73;
}

@media screen and (max-width: 660px) {
    .item_button{
        width: 100px;
        height: 38px;
        margin-top: 10px;
    }
}