.arrow-pointer {
  width: 150px;
  height: 50px;
  background: gray;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow-pointer.active {
  background-color: #c69c6d;
  color: white;
}