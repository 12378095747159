.category__heading,
.category__content .counting__wrapper span,
.category__content .category__text h6,
.category__content .category__text span,
.category__content .category__button {
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 400;
}
.category__heading {
  font-size: 19px;
  color: #000;
}

.category__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.category__content .category__inner {
  display: flex;
  justify-content: start;
  align-items: center;
}

.category__content .counting__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 52px;
  bcategory-radius: 50%;
  text-align: center;
  vertical-align: middle;
  margin-right: 10px;
}

.category__content .counting__wrapper__1 {
  background: rgba(91, 147, 255, 0.1);
}
.category__content .counting__wrapper__2 {
  background: rgba(255, 162, 0, 0.2);
}
.category__content .counting__wrapper__3 {
  background: rgba(255, 9, 2, 0.1);
}
.category__content .counting__wrapper__4 {
  background: rgba(0, 207, 232, 0.1);
}

.category__content .counting__wrapper__1 span {
  color: #5b93ff;
}
.category__content .counting__wrapper__2 span {
  color: #ffa200;
}
.category__content .counting__wrapper__3 span {
  color: #ff0902;
}
.category__content .counting__wrapper__4 span {
  color: #00cfe8;
}

.category__content .counting__wrapper span {
  font-weight: 700 !important;
  font-size: 19px;
  line-height: 28px;
}

.category__content .category__text h5 {
  font-size: 14px;
  color: #969ba0;
}

.category__content .category__text span {
  font-size: 16px;
  color: #000000;
}

.category__content .category__button {
  font-size: 14px;
  background: #fff;
  bcategory: 0;
  text-decoration: underline;
  color: #ffa200;
}

.create__product {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.category__filter__date {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 660px) {
  .create__product {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .create__product button {
    margin-left: 10px !important;
  }
}

.dealer-tabs .ant-tabs {
  border: 0 !important;
}

.dealer-tabs .ant-tabs-tab.ant-tabs-tab-active {
  background: #ffa200 !important;
}

.ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 2px !important;
}

.dealer-tabs .ant-tabs-tab {
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px !important;
  color: #4a0600;
  background: #f8f9fc !important;
  border-radius: 0px 0px 10px 10px !important;
  padding: 10px 40px !important;
}

.dealer-tabs .ant-select .ant-select-selector:focus,
.dealer-tabs .ant-select .ant-select-selector:focus-visible,
.dealer-tabs .ant-input:focus,
.dealer-tabs .ant-input:focus-visible,
.dealer-tabs .ant-select .ant-select-selection-search-input:focus,
.dealer-tabs .ant-select .ant-select-selection-search-input:focus-visible {
  box-shadow: none !important;
  outline: 0 !important;
}

.dealer-tabs .ant-input {
  height: 40px !important;
  background: #e9e9e9 !important;
  border: 1px solid #e9e9e9 !important;
  border-radius: 8px !important;
}

.dealer-tabs textarea.ant-input {
  height: 93px !important;
}

.dealer-tabs .ant-select .ant-select-selection-search-input,
.dealer-tabs .ant-select  .ant-select-selection-placeholder {
  height: 40px !important;
  line-height: 40px !important;
}

.dealer-tabs .ant-select .ant-select-selector {
  height: 40px !important;
  background: #e9e9e9 !important;
  border: 1px solid #e9e9e9 !important;
  border-radius: 8px !important;
}

.dealer-tabs .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
    line-height: 40px !important;
}

.modal-styles .ant-card-head{
  min-height: 35px !important;
}
.modal-styles .ant-card-head-title{
  padding: 10px 0 5px !important;
  font-size: 18px !important;
  font-weight: 600 !important;
}

.modal-styles .ant-card-body{
  padding: 10px 24px !important;
  font-size: 15px !important;
  text-align: left !important;
}

.modal-styles.modal-info .ai-heading, .ai-heading{
  font-weight: 600;
  font-size: 15px;
  padding-right: 10px;
}

.ant-modal-dealer .ant-modal-body{
  padding: 10px !important;
}
