.search::placeholder {
  color: white;
}

.searchIcon {
  position: absolute;
  top: 3.5%;
  left: 20%;
  color: white;
}

@media (max-width: 1250px) {
  .searchIcon {
    left: 22%;
  }
}

@media (max-width: 1150px) {
  .searchIcon {
    left: 23%;
  }
}

@media (max-width: 1070px) {
  .searchIcon {
    left: 25%;
  }
}

@media (max-width: 1050px) {
  .searchIcon {
    left: 25%;
  }
}

@media (max-width: 1020px) {
  .searchIcon {
    left: 26%;
  }
}

@media (max-width: 950px) {
  .searchIcon {
    left: 28%;
  }
}

@media (max-width: 915px) {
  .searchIcon {
    left: 28%;
  }
}

@media (max-width: 850px) {
  .searchIcon {
    left: 31%;
  }
}

@media (max-width: 800px) {
  .searchIcon {
    left: 33%;
  }

  .search {
    width: 18rem;
    padding-left: 4%;
  }
}

@media (max-width: 750px) {
  .searchIcon {
    left: 36%;
  }

  .search {
    width: 17rem;
    padding-left: 4%;
  }
}

@media (max-width: 650px) {
  .searchIcon {
    left: 40%;
  }

  .search {
    width: 15rem;
    padding-left: 4%;
  }
}

@media (max-width: 600px) {
  .searchIcon {
    left: 43%;
  }

  .search {
    width: 10rem;
    padding-left: 4%;
  }
}

@media (max-width: 550px) {
  .searchIcon {
    left: 44%;
  }

  .search {
    width: 7rem;
    padding-left: 4%;
    margin-left: -5%;
  }

  .search::placeholder {
    font-size: 1rem;
  }
}