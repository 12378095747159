.order__heading,
.order__content__company .counting__wrapper span,
.order__content__company .order__text h6,
.order__content__company .order__text span,
.order__content__company .order__button {
    font-family: "Poppins" !important;
    font-style: normal;
    font-weight: 400;
}

.order__heading {
    font-size: 19px;
    color: #000;
}

.order__content__company .counting__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 52px;
    height: 52px;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    margin-right: 10px;
}

.order__content__company .counting__wrapper__1 {
    background: rgba(91, 147, 255, 0.1);
}

.order__content__company .counting__wrapper__2 {
    background: rgba(255, 162, 0, 0.2);
}

.order__content__company .counting__wrapper__3 {
    background: rgba(255, 9, 2, 0.1);
}

.order__content__company .counting__wrapper__4 {
    background: rgba(0, 207, 232, 0.1);
}

.order__content__company .counting__wrapper__1 span {
    color: #5B93FF;
}

.order__content__company .counting__wrapper__2 span {
    color: #FFA200;
}

.order__content__company .counting__wrapper__3 span {
    color: #FF0902;
}

.order__content__company .counting__wrapper__4 span {
    color: #00CFE8;
}

.order__content__company .counting__wrapper span {
    font-weight: 700 !important;
    font-size: 19px;
    line-height: 28px;
}

.order__content__company .order__text h5 {
    font-size: 14px;
    color: #969BA0;
}

.order__content__company .order__text span {
    font-size: 16px;
    color: #000000;
}

.order__content__company .order__button > div{
    font-size: 14px;
    background: #fff;
    border: 0;
    cursor: pointer;
    text-decoration: underline;
    color: #FFA200;
    margin-right: 10px;
}

.create__product {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.order__filter__date {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 660px) {
    .create__product {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .create__product button {
        margin-left: 10px !important;
    }
}

.text-green{
    color: green;
}

.text-red{
    color: red;
}

.order-modal .ant-card-body{
    padding: 10px !important;
}

@media screen and (min-width: 1200px) {
    .border-right-lg{
        border-right: 1px solid #e4e4e4;
    }
}