.ant-select-selection-placeholder,
.ant-select-selection-item {
  text-align: initial !important;
}

.product-tabs .ant-tabs {
  border: 0 !important;
}

.product-tabs .ant-tabs-tab.ant-tabs-tab-active {
  background: #ffa200 !important;
}

.ant-tabs-tab+.ant-tabs-tab {
  margin: 0 0 0 2px !important;
}

.product-tabs .ant-tabs-tab {
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px !important;
  color: #4a0600;
  background: #f8f9fc !important;
  border-radius: 0px 0px 10px 10px !important;
  padding: 10px 40px !important;
}

.product-tabs .ant-select .ant-select-selector:focus,
.product-tabs .ant-select .ant-select-selector:focus-visible,
.product-tabs .ant-input:focus,
.product-tabs .ant-input:focus-visible {
  box-shadow: none !important;
  outline: 0 !important;
}

.product-tabs .ant-input {
  background: #e9e9e9 !important;
  border: 1px solid #e9e9e9 !important;
  border-radius: 8px !important;
}

.product-tabs .ant-picker {
  height: 40px !important;
  background: #e9e9e9 !important;
  border: 1px solid #e9e9e9 !important;
  border-radius: 8px !important;
  width: 100% !important;
}

.product-tabs .ant-select .ant-select-selector {
  height: 40px !important;
  background: #e9e9e9 !important;
  border: 1px solid #e9e9e9 !important;
  border-radius: 8px !important;
}

.ant-drawer-body {
  padding: 15px !important;
}