.titleOnDetails {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 21px !important;
    line-height: 24px !important;
    display: flex;
    margin-bottom: 20px !important;
    align-items: center;

    color: #000000;
}


.tableOfHeading {


    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 18px !important;
    /* identical to box height, or 133% */
    display: flex;
    align-items: center;

    color: #000000;
}

.box_chat {
    margin-top: 20px;
    border-top: 6px solid rgba(53, 93, 255, 0.1) !important;
    border-right: 3px solid rgba(53, 93, 255, 0.1) !important;
}


.box_chat_Second {
    margin-top: 20px;
    border-top: 6px solid rgba(53, 93, 255, 0.1) !important;
    border-left: 3px solid rgba(53, 93, 255, 0.1) !important;
}

.Upload_Button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 148px !important;
    height: 30px !important;
    background: #1DBF73 !important;
    border-radius: 50px !important;
}