/* @import "~antd/dist/antd.css"; */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

body {
  margin: 0;
  padding: 0;
  background-color: #de923b;
  font-family: "Poppins", sans-serif !important;
}

@font-face {
  font-family: "timeShow";
  src: local("time"), url(./font/stylish-time.ttf);
}

@font-face {
  font-family: "Jameel Noori Nastaleeq";
  src: url('./font/jameel-noori-nastaleeq-regular.ttf');
}

.primaryColor {
  color: #603813;
}

.blockOrderStat {
  height: 150px;
  background: #f2e6da;
  color: white;
  width: 150px;
  max-width: 150px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.blockOrderDashboardStat {
  height: 120px;
  background: #f2e6da;
  color: white;
  min-width: 150px;
  border-radius: 20px;
  display: flex;

  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.blockOrderDashboardStat h4 {
  margin-left: 10px;
}

.blockOrderDashboardStat p {
  margin-left: 10px;
  margin-top: 10px;
  color: #fbc36a;
}

.paid {
  color: green
}

.unpaid {
  color: red
}

.tableRow:hover {
  cursor: pointer;
}

.react-calendar button {
  color: black;
}

.react-date-picker__inputGroup__input {
  background-color: white;
  color: black;
  width: 40px !important;
  text-align: center !important;
}

.ant-select-auto-complete.ant-select .ant-input {
  background-color: white;
  color: black;
  font-size: 12px;
}

.tableFeed .ant-pagination {
  display: none;
}

.ant-typography-expand {
  color: #de923b !important;
}

.ant-table-column-title {
  color: #de923b;
}

.ant-form-item {
  margin-bottom: 10px !important;
}

.ant-table table {
  background: #fff !important;
}

.ant-table-thead>tr>th {
  background: #fff !important;
}

/* .gray {
  background-color: lightgray;
} */
.white {
  background-color: white;
}

/* .ant-modal-body .drop-down {
  border: #de923b 3px solid !important;
  border-radius: 10px;
} */


.consumer_price_section .ant-input-disabled {

  color: black !important;

}